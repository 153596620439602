<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link class="footer-content__list--anchore" :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        routes: [
          {
            route      :  'sportsbook',
            name        : 'sportsbook'

          },
          {
            route      :  'casino',
            name        : 'casino'

          },
          {
            route      :  'racebook',
            name        : 'racebook'

          },
          {
            route      :  'live-betting',
            name        : 'live betting'

          },
          {
            route      :  'house-rules',
            name        : 'house rules'
          },
          {
            route      :  'sports-rules',
            name        : 'sport rules'
          },
          {
            name       :  'horse-rules',
            route      :  'horse-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ]
      }
    },
  };
</script>
