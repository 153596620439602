<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="">
        <a href="/">
          <img class="header-content__img" src="@/assets/images/logo.png" alt="thirddown" />
        </a>
      </div>
      <div class="header-content__cta">
        <div class="header-buttons">
          <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
            <input class="login__form--inputs" type="password" placeholder="PASSWORD" name="password" id="password">
            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
          <!-- Desktop form -->
          <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
            <input class="login__form--inputs" type="password" name="password" placeholder="PASSWORD" id="password">
            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div>
      <nav class="header-content__nav2">
        <div class="header-nav__container">
          <ul class="header-nav__menu">
            <div class="header-content__info">
              <ul class="header-content__info--list">
                <li class="header-content__info--list-item">
                  <router-link to="sportsbook"><img class="header-content__icon " src="@/assets/images/icons/sports.svg"
                      alt="Sports-icons">Sports</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="casino"><img class="header-content__icon " src="@/assets/images/icons/casino.svg"
                      alt="Casino-icons">Casino</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="racebook"><img class="header-content__icon " src="@/assets/images/icons/horses.svg"
                      alt="Racebook-icons">RACEBOOK</router-link>
                </li>
                <li class="header-content__info--list-item">
                  <router-link to="live-betting"><img class="header-content__icon " src="@/assets/images/icons/live.svg"
                      alt="Racebook-icons">Live betting</router-link>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </nav>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from "@/components/modal.vue";

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
  },
  setup:() => {
      const backendUrl = "premierwager.live";
      const DGS_SITEID = 1000;
      
      return {
        backendUrl,
        DGS_SITEID
      }
    },
};
</script>
